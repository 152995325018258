import React from "react";
import * as Colors from "@constants/colors";
import ButtonLink from "@components/button-link";
import Section from "@components/section";
import { Wrapper } from "@components/shared.styled";
import { Block, Message, ButtonContainer } from "./get-started.styled";

const defaultMessage = <span>Let’s Get Started with Atwave.</span>;

const GetStarted = ({
  message,
  backgroundColor = Colors.beachTeal,
  blockBackgroundColor = Colors.goldenHourLighter,
  buttonLabel = "Get in touch",
}) => (
  <Section backgroundColor={backgroundColor}>
    <Wrapper>
      <Block blockBackgroundColor={blockBackgroundColor}>
        <Message>{message || defaultMessage}</Message>
        <ButtonContainer>
          <ButtonLink text={buttonLabel} to="/contact" />
        </ButtonContainer>
      </Block>
    </Wrapper>
  </Section>
);

export default GetStarted;
