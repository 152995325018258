import { BlockSupTitle } from "@components/shared.styled";
import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import * as Colors from "@constants/colors";
import Section from "@components/section";
import animation from "@animations/partners.json";
import {
  StyledWrapper,
  LargeHeader,
  AnimationContainer,
} from "./partnership.styled";

const Partnership = ({ data }) => {
  const content =
    data !== undefined
      ? data
      : {
          backgroundColor: Colors.white,
          smallHeader: "In good company",
          largeHeader: "The Platform of Choice for Major Brands",
        };
  const { backgroundColor, smallHeader, largeHeader } = content;
  const animationRef = useRef(null);

  useEffect(() => {
    const lottieAnimation = lottie.loadAnimation({
      container: animationRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    });
    return () => lottieAnimation.destroy();
  }, []);

  return (
    <Section backgroundColor={backgroundColor}>
      <StyledWrapper>
        {smallHeader && <BlockSupTitle>{smallHeader}</BlockSupTitle>}
        <LargeHeader>{largeHeader}</LargeHeader>
        <AnimationContainer ref={animationRef} />
      </StyledWrapper>
    </Section>
  );
};

export default Partnership;
