import styled from "styled-components";
import { Screens } from "@constants/screens";

const AnimatedContent = styled.div`
  flex: auto;
`;

const StyledSection = styled.section`
  display: flex;
  justify-content: ${({ justify }) => justify};
  flex-wrap: wrap;
  align-items: center;
  padding: ${({ yPadding }) => yPadding}px 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};

  @media (max-width: ${Screens.sm}) {
    padding: ${({ yPadding }) => yPadding * 0.75}px 0;
  }
`;

export { AnimatedContent, StyledSection };
