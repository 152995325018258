import styled from "styled-components";
import * as Colors from "@constants/colors";
import { ClearfaceFont } from "@constants/fonts";
import { Screens } from "@constants/screens";

const Container = styled.section`
  background: ${({ backgroundColor = Colors.beachTeal }) => backgroundColor};
  padding: 108px 0;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const Block = styled.div`
  background: ${({ blockBackgroundColor = Colors.goldenHourLighter }) =>
    blockBackgroundColor};
  border-radius: 10px;
  padding: 80px;
  max-width: 720px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${Screens.sm}) {
    width: 100%;
    padding: 40px 20px;
  }
`;

const Message = styled.p`
  font-size: 40px;
  font-family: ${ClearfaceFont};
  font-weight: bold;
  line-height: 1.3;
  color: ${Colors.black};
  max-width: 474px;
  margin: 0 auto 32px;

  @media (max-width: ${Screens.xs}) {
    max-width: 100%;
    font-size: 28px;
    margin-bottom: 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export { Container, Block, Message, ButtonContainer };
