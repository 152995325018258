import React, { useRef, useEffect } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import { applyFadeInAnimation } from "@helpers/animations";
import { StyledSection } from "./section.styled.js";
import * as Colors from "@constants/colors";

gsap.registerPlugin(ScrollTrigger);

const Section = ({
  backgroundColor = Colors.white,
  yPadding = 112,
  textColor = Colors.charcoal,
  justify = "center",
  animated = true,
  children,
  className = "",
}) => {
  const fadeInElementRef = useRef(null);

  useEffect(() => {
    if (animated === true) {
      const { timeline, scrollTriggerInstance } = applyFadeInAnimation(
        fadeInElementRef
      );
      return () => {
        timeline.pause(0).kill();
        scrollTriggerInstance.kill(true);
      };
    }
  });

  return (
    <StyledSection
      backgroundColor={backgroundColor}
      justify={justify}
      textColor={textColor}
      yPadding={yPadding}
      ref={fadeInElementRef}
      className={className}
    >
      {children}
    </StyledSection>
  );
};

export default Section;
