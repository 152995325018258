import styled from "styled-components";
import { Screens } from "@constants/screens";
import { ClearfaceFont } from "@constants/fonts";
import * as Colors from "@constants/colors";
import { Wrapper } from "@components/shared.styled";

const StyledWrapper = styled(Wrapper)`
  background: ${({ backgroundColor = Colors.white }) => backgroundColor};
  text-align: center;
`;

const AnimationContainer = styled.div`
  width: 100%;
  height: 100px;
`;

const LargeHeader = styled.h1`
  font-family: ${ClearfaceFont};
  font-size: 40px;
  line-height: 1.3;
  max-width: 500px;
  text-align: center;
  margin: 0 auto 72px;

  @media (max-width: ${Screens.lg}) {
    font-size: 28px;
    margin-bottom: 48px;
  }
`;

export { AnimationContainer, StyledWrapper, LargeHeader };
